
(function($, undefined)
{
	"use strict";

	var $window = $(window);

	var forceRedraw = function(element)
	{
	    if (!element) { return; }

	    var n = document.createTextNode(' ');
	    var disp = element.style.display;  // don't worry about previous display style

	    element.appendChild(n);
	    element.style.display = 'none';

	    setTimeout(function(){
	        element.style.display = disp;
	        n.parentNode.removeChild(n);
	    },1); // you can play with this timeout to make it as short as possible
	}

	var normalizeTeasers = function()
	{
		$('.layout_latest').data('normalized', false);
		$('.layout_latest').each(function()
		{
			// get the teaser element
			var $teaser = $(this);

			// check if already normalized
			if ($teaser.data('normalized'))
			{
				return true;
			}

			// get siblings
			var $siblings = $teaser.siblings('.layout_latest').addBack();

			// get the max offset for image
			var maxOffset = 0;
			$siblings.css('padding-top',0);
			$siblings.find('figure').each(function()
			{
				var offset = $(this).position().top;
				if (offset > maxOffset && offset < 200)
				{
					maxOffset = offset;
				}
			});

			// process each sibling
			$siblings.each(function()
			{
				// get the sibling
				var $sibling = $(this);

				// find figure
				var $figure = $sibling.find('figure');

				// check for figure
				if ($figure.length == 0)
				{
					return true;
				}

				// set padding
				$sibling.css('padding-top', (maxOffset - $sibling.find('figure').position().top) + 'px');

				// set to normalized
				$sibling.data('normalized', true);
			});
		});
	};

	var containerMinHeight = function()
	{
		$('#container').css('min-height', ($window.height() - $('#header').outerHeight() - $('#footer').outerHeight()) + 'px');
	};

	$(document).ready(function()
	{
		/**
		 * Smooth scrolling to anchor
		 */
		$('a[href*=\\#]').on('click', function(event)
		{
			if ($(this.hash).length > 0 )
			{
			    event.preventDefault();
			    $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
			}
		});

		/**
		 * Normalize teasers
		 */
		normalizeTeasers();

		/**
		 * Surprise me more
		 */
		$('.surprise-me-more a').click(function(e)
		{
			e.preventDefault();
			$('.surprise-me-list .layout_latest').show();
			$(this).hide();
			return false;
		});

		/**
		 * Article filter
		 */
		$('.article-filter a').click(function(e)
		{
			e.preventDefault();
			var $a = $(this);
			$('body').addClass('loading');
			$.get($a.attr('href'), function(response)
			{
				$('#start-newslist').replaceWith($(response).find('#start-newslist'));
				normalizeTeasers();

			}).complete(function()
			{
				$('body').removeClass('loading');
			});
			return false;
		});

		$('.cat-menu a').click(function(e)
		{
			e.preventDefault();
			var $a = $(this);
			$('body').addClass('loading');
			$.get($a.attr('href'), function(response)
			{
				$('#start-newslist1').replaceWith($(response).find('#start-newslist1'));
				$('#start-newslist2').replaceWith($(response).find('#start-newslist2'));
				$('#start-newslist3').replaceWith($(response).find('#start-newslist3'));
				normalizeTeasers();

			}).complete(function()
			{
				$('body').removeClass('loading');
			});
			$('html, body').animate({
	        	scrollTop: $('#start-newslist1').offset().top
	    	}, 1000);

			$('.cat-menu li').removeClass('act');
    		$a.closest('li').addClass('act');

			return false;
		});


		/**
		 * #container min height
		 */
		containerMinHeight();


		/**
		 * Random animation for category menu
		 */
		$('.utopia-category-menu').each(function()
		{
			var $this = $(this);
			var $links = $this.find('li');

			var hover = false;
			$links.hover(function() {
				hover = true;
				$links.removeClass('act');
			}, function() {
				hover = false;
			});

			var randomAnimation = function()
			{
				var random = Math.floor(Math.random() * $links.length);
				$links.removeClass('act');

				if (!hover)
				{
					$links.eq(random).addClass('act');
				}

				var time = 3000 + 1000 * Math.random();
				setTimeout(randomAnimation, time);
				//setTimeout(function() { forceRedraw($links.get(random)); }, time + 10);
			};

			randomAnimation();
		});


		/**
		 * Parallax for teasers
		 */
		// no animations when not supported
		if (undefined === window.requestAnimationFrame)
		{
			return true;
		}

		var executeParallax = function(timestamp)
		{
			var windowHeight = $window.height();
			var scrollPosTop = $window.scrollTop();
			var scrollPosBottom = scrollPosTop + windowHeight;

			$('.layout_latest figure, .layout_full h1 + figure').each(function()
			{
				var $figure = $(this);
				var $img = $figure.find('img');
				var offset = $figure.offset().top;
				var factor = (scrollPosBottom - offset) / windowHeight;
				var range = $img.height() - $figure.outerHeight();
				var top = range * factor;
				$img.css('top',(-range+top)+'px');
			});

			window.requestAnimationFrame(executeParallax);
		};

		window.requestAnimationFrame(executeParallax);
	});

	$window.on('load', function()
	{
		/**
		 * Normalize teasers
		 */
		normalizeTeasers();
	});

	$window.resize(function()
	{
		/**
		 * Normalize teasers
		 */
		normalizeTeasers();

		/**
		 * #container min height
		 */
		containerMinHeight();
	});

})(jQuery);
